<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">
			<div v-if="$i18n.locale == 'en'">
				<h2 class="display-4 mb-0"
						v-html="$t('smart_wallet_1')" />
				<h2 v-html="$t('smart_wallet_2')" />
				<p v-html="$t('smart_wallet_3')" />

				<p v-html="$t('smart_wallet_4')" />
				<p v-html="$t('smart_wallet_5')" />
				<a href="https://fraction.immo/post/fiat"
					 v-html="$t('smart_wallet_6')" />


				<h2 v-html="$t('smart_wallet_7')" />
				<p v-html="$t('smart_wallet_8')" />
				<h2 v-html="$t('smart_wallet_9')" />
				<p v-html="$t('smart_wallet_10')" />

				<h3 v-html="$t('smart_wallet_11')" />
				<a href="open"
					 v-html="$t('smart_wallet_12')" />

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-1.png" />
						<br /> <br /> <a target="_target"
							 href="https://wallet.fraction.re"
							 v-html="$t('smart_wallet_13')" />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-2.png" />
						<br /> <br />
						<b v-html="$t('smart_wallet_14')" />
						<br />
					</div>

					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-3.png" />
						<br /> <br />
						<b v-html="$t('smart_wallet_15')" />
						<br /><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-4.png" /><br />
						<br />
						<b v-html="$t('smart_wallet_16')" />
						<br />
					</div>

					<h3 class="my-12"
							v-html="$t('smart_wallet_17')" />
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-5.png" /><br />
						<br /> <br />
						<b v-html="$t('smart_wallet_18')" />
						<br />
					</div>


					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-6.png" /><br />
						<br /> <br />
						<b v-html="$t('smart_wallet_19')" />
						<br /><br />
					</div>
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-7.png" /><br />
						<br /> <br />
						<b v-html="$t('smart_wallet_20')" />
						<br />
					</div>
					<h3 class="my-12"
							v-html="$t('smart_wallet_21')" />
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-9.png" /><br />
						<br /> <br />
						<b v-html="$t('smart_wallet_22')" />
						<br /><br />
					</div>
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-10.png" /><br />
						<br /> <br />
						<b v-html="$t('smart_wallet_23')" />
						<br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-11.png" /><br />
						<br /> <br />
						<b v-html="$t('smart_wallet_24')" />
						<br />
					</div>


				</div>



				<div class="row ">
					<h3 class="mt-12"
							v-html="$t('smart_frenchcontrat_14')" />
					<p v-html="$t('smart_wallet_25')" />
					<p v-html="$t('smart_wallet_26')" />
				</div>
			</div>


			<div v-if="$i18n.locale == 'fr'">
				<h2 class="display-4 mb-0">
					Premier investissement </h2>
				<h2>
					Cryptomonnaie et unité de compte</h2>

				<p>
					L'achat de token en cryptomonnaie sur la blockchain stellar se fait en Lumens. Cette cryptomonnaie a pour sigle XLM. Elle est utilisée pour
					payer les commissions de transactions.
					On peut facilement en acheter sur moonpay ou convertir du Bitcoin, Ether ou autre sur des plateformes d'échanges comme Binance, CoinHouse...
					<br />
					<br />
					Pour faciliter les échanges et les conversions de tokens tout en conservant un référentiel financier facilement compréhensible
					par les utilisateurs, les sociétés qui participent à l'écosystème fraction.re ont émis des tokens reprenant la valeur de la monnaie fiat.
					<br /> <br /> Pour les utilisateurs européens, il s'agit du fcEURO ( sigle fcEUR), pour les américains le fcDollars (sigle fcUSD )
					ou pour les anglais le fcPound (sigle fcGBP ).
					<br />
					<a href="https://fraction.immo/post/fiat"> Un post explique plus en détail les unités de compte de l'écosytème Fraction.re</a>

				</p>
				<h2>
					Principe de base</h2>
				<p>

					Un titulaire de compte veut utiliser le wallet de Fraction.re pour vendre des Lumens contre des unités de compte de l'écosystème Fraction afin de pouvoir acheter des tokens.

				</p>
				<h2>
					Un exemple concret</h2>
				<p>
					Emma va utiliser le wallet de Fraction.re pour la première fois, elle va le configurer en important ses clés.
					<b />Ensuite, elle va vendre ses Lumens contre des fcEURO et acheter des Berlcoins.
					<br />
				</p>

				<h3>
					Configuration du Wallet</h3>
				<p>Vous devez disposer d'un jeu de clés dans la blockchain Stellar.<a href="https://fraction.immo/open"> Si ce n'est pas le cas, lire l'article suivant </a>
				</p>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-1.png" />
						<br /> <br /> <b> Emma se rend sur <a target="_target"
								 href="https://wallet.fraction.re">https://wallet.fraction.re</a></b> et scanne son adresse publique<br />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-2.png" />
						<br /> <br /> <b> Emma choisit de remplacer la clé privé par un code PIN. Elle scanne sa clé privée et choisit un code de 6 caractères. </b><br />
						<br />
					</div>

					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-3.png" />
						<br /> <br />
						<b>Il est aussi possible d'utiliser un système de double authentification en scannant le QRCode dans Google Authentificator ou FreeOTP et en saisisant le code de vérification.
						</b> <br /><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-4.png" /><br />
						<br />
						<b>Le compte d'Emma s'affiche, elle sélectionne le menu VENTE
						</b> <br />
					</div>

					<h3 class="my-12">
						Vente de Lumens</h3>

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-5.png" /><br />
						<br /> <br />
						<b>La liste des tokens s'affiche, Emma sélectionne ses Lumens (XLM), le menu s'affiche, elle appuie sur ORDRE DE VENTE
						</b> <br />
					</div>


					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-6.png" /><br />
						<br /> <br />
						<b>Emma décide de vendre 9900 Lumens (XLM) contre des unités de compte fcEURO. Elle conserve le cours du jours, saisie son code PIN et valide.
						</b> <br /><br />
					</div>
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-7.png" /><br />
						<br /> <br />
						<b>Emma à désormais 2078 fcEURO et 100 XLM. Elle sélectionne le menu ACHAT
						</b> <br />
					</div>
					<h3 class="my-12">
						Achat de Berlcoins</h3>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-9.png" /><br />
						<br /> <br />
						<b>La liste des tokens disponibles à l'achat s'affiche, elle sélectionne Berlcoin, le menu des achats s'affiche, elle appuie sur ORDRE D'ACHAT.
						</b> <br /><br />
					</div>
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-10.png" /><br />
						<br /> <br />
						<b>Emma décide d'acheter 20 Berlcoins, elle conserve le cours du jour, saisie son code PIN et valide.
						</b> <br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-11.png" /><br />
						<br /> <br />
						<b>Emma à désormais 20 Berlcoins.
						</b> <br />
					</div>


				</div>



				<div class="row ">
					<h3 class="mt-12">
						Coût de l'opération</h3>
					<p> Emma a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre de vente et le même montant pour l'ordre d'achat.
						<br /> <br /> Au final moins de 1 centime d'euro.
					</p>
				</div>
			</div>
		</div>


	</div>

</section>
</template>


<script>
export default {
	name: 'wallet',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.$route.params.lang != undefined) this.$i18n.locale = this.$route.params.lang
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
